<template>
  <div>
    <div class="banner-container">
      <div class="banner-text">
        <h1>{{ $t("aboutUs.title") }}</h1>
        <p class="mt-2">{{ $t("aboutUs.description") }}</p>
      </div>
      <div class="banner"></div>
    </div>
    <section class="container content">
      <div class="row">
        <div class="col-md-5 my-4">
          <img
            class="img-fluid"
            src="../assets/images/what-is-gloveler.jpg"
            alt=""
          />
        </div>
        <div class="col-md-7 my-4">
          <h1>{{ $t("aboutUs.whatIsGloveler") }}</h1>
          <p>
            {{ $t("aboutUs.whatIsGlovelerContent") }}
          </p>
        </div>
      </div>
    </section>
    <StatisticsSection />
  </div>
</template>

<script>
import StatisticsSection from "../components/StatisticsSection.vue";
export default {
  name: "AboutUs",
  components: {
    StatisticsSection,
  },
};
</script>

<style scoped>
.banner-container {
  position: relative;
  text-align: center;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  z-index: 2;
  font-weight: bold;
}

.banner-text h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 45px;
}

.banner {
  height: 70vh;
  position: relative;
  background-image: url(../assets/images/about-us.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin: auto;
}
.banner:before {
  background-color: rgba(255, 118, 43, 0.7);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.content {
  margin-top: 70px;
  margin-bottom: 70px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content p {
  color: #969696;
}
.mt-10 {
  margin-top: 90px;
}
@media only screen and (max-width: 500px) {
  .banner {
    height: 30vh;
  }

  .content {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .banner-text {
    width: 100%;
  }

  .content h1 {
    font-size: 28px;
  }

  .banner-text h1 {
    font-size: 30px;
  }

  .mt-10 {
    margin-top: 35px;
  }
}
</style>
