<template>
  <div class="statistics">
    <div class="container">
      <div class="text-center">
        <h1 class="font-weight-bold">{{ $t("statistics.lastMonth") }}</h1>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mt-md-5 mt-4"
      >
        <div class="text-center border-right flex-grow-1">
          <h2 class="font-weight-bold">255</h2>
          <p>{{ $t("statistics.guestsStayed") }}</p>
        </div>
        <div class="text-center flex-grow-1">
          <h2 class="font-weight-bold">3,210+</h2>
          <p>{{ $t("statistics.partnerEcosystem") }}</p>
        </div>
        <div class="text-center border-left flex-grow-1">
          <h2 class="font-weight-bold">24/6</h2>
          <p>{{ $t("statistics.languagesCount", { count: "11" }) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsSection",
};
</script>

<style scoped>
.statistics {
  background: #444;
  color: #fff;
  padding: 60px;
}

@media only screen and (max-width: 500px) {
  .statistics {
    padding: 30px 0;
  }

  .statistics h1 {
    font-size: 26px;
  }

  .statistics h2 {
    font-size: 18px;
  }

  .statistics p {
    font-size: 13px;
  }
}
</style>
